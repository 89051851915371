import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashboardLayout from 'components/common/DashboardLayout';
import WelcomeBar from 'components/Dashboard/WelcomeSection';
import GoBack from 'components/common/GoBack';

import './style.scss';

import {
  clearGatewayTransferConfirmation,
} from 'redux/actions/moneyTransfer/gatewayTransferConfirmationData';

const ContentBox = props => {
  const history = useHistory();
  const dispatch = useDispatch()

  const onClickHandler = () => {
    history.goBack();
    if (props.backBtn !== undefined) {
      props.backBtn();
      clearGatewayTransferConfirmation()(dispatch)
    }
  };

  return (
    <DashboardLayout>
      <div>
        <WelcomeBar>
          <div className="head-content">
            <div className="go-back">
              <GoBack style onClickHandler={onClickHandler} />
            </div>
            <h2 className="head-title">
              {global.translate(`${props.title}`)}
            </h2>
            <div className="clear" />
          </div>
        </WelcomeBar>
        <div className="content-box__out-container">
          <div className="context-box__out-forms">
            <div>{props.children}</div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContentBox;
