import {
    REDEEM_REFERRAL_START,
    REDEEM_REFERRAL_SUCCESS,
    REDEEM_REFERRAL_ERROR,
    CLEAR_REDEEM_REFERRAL,
  } from 'constants/action-types/referral/redeemReferal';
  
  export default (state, { type, payload }) => {
    switch (type) {
      case REDEEM_REFERRAL_START:
        return {
          ...state,
          redeemReferral: {
            ...state.redeemReferral,
            loading: true,
            success: false,
            OK: '',
          },
        };
      case REDEEM_REFERRAL_ERROR:
        return {
          ...state,
          redeemReferral: {
            ...state.redeemReferral,
            error: payload,
            loading: false,
            success: false,
          },
        };
      case REDEEM_REFERRAL_SUCCESS:
        return {
          ...state,
          redeemReferral: {
            ...state.redeemReferral,
            ...payload,
            loading: false,
            error: null,
            success: true,
          },
        };
        case CLEAR_REDEEM_REFERRAL:
        return {
          ...state,
          redeemReferral: {
            ...state.redeemReferral,
            loading: false,
            success: false,
            error: null,
          },
        };
  
      default:
        return null;
    }
  };
  