/* eslint-disable */
import './SideBar.scss';

import nLogo from 'assets/images/nLogo.svg';
import NavAddMoney from 'assets/images/NavAddMoney.svg';
import CreditCardIcon from 'assets/images/ManageCard.svg';
import NavContactIcon from 'assets/images/NavContactIcon.svg';
import nHomeIcon from 'assets/images/nHomeIcon.svg';
import nMoneyTransferIcon from 'assets/images/nMoneyTransferIcon.svg';
import nPayBillsSidebarIcon from 'assets/images/nPayBillsSidebarIcon.svg';
import nMicroloansIcon from 'assets/images/nMicroloansIcon.svg';
import nGroupIcon from 'assets/images/nGroupIcon.svg';
import nMyContactsIcon from 'assets/images/nMyContactsIcon.svg';
import nReportBugIcon from 'assets/images/nReportBugIcon.svg';
import nCompanionCardsIcon from 'assets/images/nCompanionCardsIcon.png';
import nTransactionsIcon from 'assets/images/nTransactionsIcon.svg';
import nMyWalletsIcon from 'assets/images/nMyWalletsIcon.svg';
import nTopUpIcon from 'assets/images/nTopUpIcon.svg';
import nSavingActiveIcon from 'assets/images/SavingsIcon.png';
import organizationIcon from "assets/images/organization-icon.svg";
import NavReportBugIcon from 'assets/images/NavReportBug.svg';
import NavServicesIcon from 'assets/images/NavServicesIcon.svg';
import NavTransaction from 'assets/images/NavTransactionIcon.svg';
import NavTransferIcon from 'assets/images/money-transfer.svg';
import NavWalletIcon from 'assets/images/NavWalletIcon.svg';
import CurrencyExchangeContainer from 'containers/MoneyTransfer/Exchange/Exchange';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AppStore from 'assets/images/app-store.svg';
import GooglePlay from 'assets/images/google-play.svg';
import NavMicroloan from 'assets/images/microloan/microloan-icon.svg';
import toggleSidebar, {
  setManageContacts,
} from 'redux/actions/dashboard/dashboard';
import { Icon, Image } from 'semantic-ui-react';
import PINConfirmationModal from 'components/common/PINConfirmationModal';
import './SideBar.scss';

const SideBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [routeName, setRouteName] = useState('');
  const [sendMoneyOpen, setSendMoneyOpen] = useState(false);
  // TESTING MODAL

  const [openPinModal, setOpenPinModal] = useState(false);

  const { isSidebarActive } = useSelector(
    ({ dashboard }) => dashboard.dashboardData,
  );
  const toggleMenu = name => {
    setExpand(!expand);
    setRouteName(name);
  };

  const { userData } = useSelector(({ user }) => user);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <>
      <PINConfirmationModal
        open={openPinModal}
        setOpen={setOpenPinModal}
      />
      <aside
        className={`sidenav ${
          isSidebarActive ? 'active-sidebar' : ''
        }`}
        style={{
          height: '100%',
          position: 'fixed',
          background: '#fff',
        }}
      >
        <CurrencyExchangeContainer
          setSendMoneyOpen={setSendMoneyOpen}
          sendMoneyOpen={sendMoneyOpen}
        />

        <button type="button" className="sidenav__close-icon">
          <Icon name="close" size="large" />
        </button>
        <div
          onClick={() => {
            toggleSidebar(dispatch);
          }}
          className="sidebar"
        >
          <div
            onClick={() => {
              toggleSidebar(dispatch);
              history.push({
                pathname: '/',
              });
            }}
            className="dash_logo"
          >
            <Image src={nLogo} />
          </div>
          <ul>
            <li>
              <Link
                to="/"
                onClick={() => {
                  toggleSidebar(dispatch);
                  setManageContacts(dispatch);
                }}
                className={splitLocation[1] === '' ? 'isActive' : ''}
              >
                <i>
                  <Image src={nHomeIcon} />
                </i>
                {global.translate('Home')}
                {splitLocation[1] === '' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/money-transfer"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'money-transfer'
                    ? 'isActive'
                    : ''
                }
              >
                <i>
                  <Image src={nMoneyTransferIcon} />
                </i>
                {global.translate('Money Transfer')}
                <>
                  {splitLocation[1] === 'money-transfer' ? (
                    <Icon
                      name="caret right"
                      className="sidebar_caret"
                    />
                  ) : (
                    <></>
                  )}
                </>
              </Link>
            </li>

            <li>
              <Link
                to="/add-money"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'add-money' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nTopUpIcon} />
                </i>
                {global.translate('Top Up')}
                {splitLocation[1] === 'add-money' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/pay-bills"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'pay-bills' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nPayBillsSidebarIcon} />
                </i>
                {global.translate('Pay bills')}
                <>
                  {splitLocation[1] === 'pay-bills' ? (
                    <Icon
                      name="caret right"
                      className="sidebar_caret"
                    />
                  ) : (
                    <></>
                  )}
                </>
              </Link>
            </li>
            
            <li>
              <Link
                to="/wallets"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'wallets' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nMyWalletsIcon} />
                </i>
                {global.translate('My Wallets')}{' '}
                {splitLocation[1] === 'wallets' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/transactions"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'transactions'
                    ? 'isActive'
                    : ''
                }
              >
                <i>
                  <Image src={nTransactionsIcon} />
                </i>
                {global.translate('Transactions')}
                {splitLocation[1] === 'transactions' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/contacts"
                onClick={() => {
                  toggleSidebar(dispatch);
                  setManageContacts(dispatch);
                }}
                className={
                  splitLocation[1] === 'contacts' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nMyContactsIcon} />
                </i>
                {global.translate('My Contacts')}
                {splitLocation[1] === 'contacts' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/microloan"
                className={
                  splitLocation[1] === 'microloan' ? 'isActive' : ''
                }
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
              >
                <i>
                  <Image src={nMicroloansIcon} />
                </i>
                {global.translate('Microloan')}
                {splitLocation[1] === 'microloan' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/savings"
                className={
                  splitLocation[1] === 'savings' ? 'isActive' : ''
                }
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
              >
                <i>
                  <Image src={nSavingActiveIcon} />
                </i>
                {global.translate('Savings')}
                {splitLocation[1] === 'savings' && (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                )}
              </Link>
            </li>

            {!userData?.loading && userData?.data?.PartnerOrganization === "YES" && 
              <li>
                <Link
                  to="/organization"
                  className={
                    splitLocation[1] === 'organization' ? 'isActive' : ''
                  }
                  onClick={() => {
                    toggleSidebar(dispatch);
                  }}
                >
                  <i>
                    <Image src={organizationIcon} />
                  </i>
                  {global.translate('My Organization')}
                  {splitLocation[1] === 'organization' && (
                    <Icon
                      name="caret right"
                      className="sidebar_caret"
                    />
                  )}
                </Link>
              </li>
            }

            <li>
              <Link
                to="/credit-cards"
                onClick={() => {
                  toggleSidebar(dispatch);
                }}
                className={
                  splitLocation[1] === 'credit-cards'
                    ? 'isActive'
                    : ''
                }
              >
                <i>
                  <Image src={nCompanionCardsIcon} />
                </i>
                {global.translate('Companion cards')}
                {splitLocation[1] === 'credit-cards' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>

            <li>
              <Link
                to="/services"
                onClick={() => {
                  toggleSidebar(dispatch);
                  setManageContacts(dispatch);
                }}
                className={
                  splitLocation[1] === 'services' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nGroupIcon} />
                </i>
                {global.translate('Services')}
                {splitLocation[1] === 'services' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>
            <li>
              <Link
                to="/get-help"
                onClick={() => {
                  toggleSidebar(dispatch);
                  setManageContacts(dispatch);
                }}
                className={
                  splitLocation[1] === 'get-help' ? 'isActive' : ''
                }
              >
                <i>
                  <Image src={nReportBugIcon} />
                </i>
                {global.translate('Report a bug')}
                {splitLocation[1] === 'get-help' ? (
                  <Icon
                    name="caret right"
                    className="sidebar_caret"
                  />
                ) : (
                  <></>
                )}
              </Link>
            </li>
            <li>
              <div className="mobile-apps">
                <h4>
                  {global.translate('Download our mobile app.')}
                </h4>
                <a
                  href="https://play.google.com/store/apps/details?id=technology.ossix.m2umoney"
                  target="_blank"
                  alt="Android"
                >
                  <Image src={GooglePlay} />
                </a>
                <a
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                  alt="iOS"
                >
                  <Image src={AppStore} />
                </a>
                <div className="clear" />
              </div>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
