import React, { useEffect, useState } from 'react';
import bankIcon from 'assets/images/bankIcon.svg';
import InputLoader from 'assets/images/LoaderRectangle.svg';
import { Button, Image, Input, Dropdown } from 'semantic-ui-react';
import { BANK_PROVIDERS_TYPE } from 'constants/providersTypes';
import { Link } from 'react-router-dom';

const TransferFundsToBank = prop => {
  const {
    checking,
    enableNextProcess,
    setCurrentPage,
    providersList,
    form,
    currentOption,
    onHandleChangeTransferFundsToBankInput,
    onSubmitWalletTransactionConfirmation,
    linkedBankAccounts,
    bankOptions,
    setBankOptions,
    selectedBankAccount,
    setSelectedBankAccount,
    countries,
  } = prop;
  const [getBanks, setGetBanks] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const newList = [];
    const providers = providersList?.data?.map(item => {
      if (item?.TargetType === BANK_PROVIDERS_TYPE) {
        newList.push(item);
      }
    });
    setGetBanks(newList);
  }, []);

  const selectBankHandler = (_, { value }) => {
    setSelectedBankAccount(value);
  };

  useEffect(() => {
    const newList = [];
    const countriesList = Array.isArray(countries) && countries.map(item => {
      newList.push({
        key: item?.CountryCode,
        text: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <img
              src={item?.Flag}
              style={{
                width: '15px',
              }}
            />
            <span
              style={{
                fontSize: '12',
                marginLeft: '10px',
                marginTop: '3px',
              }}
            >
              {item?.CountryCode}
            </span>
          </div>
        ),
        value: item?.CountryCode,
      });
    });
    setCountriesList(newList);
  }, [countries]);

  useEffect(() => {
    if (Array.isArray(linkedBankAccounts?.data)) {
      setBankOptions(
        linkedBankAccounts?.data?.map(bank => ({
          key: bank?.AccountNumber,
          text: `${bank?.BankName} (${bank?.AccountNumber}) ${bank?.Currency}`,
          value: bank,
        })),
      );
      setSelectedBankAccount(linkedBankAccounts?.data[0]);
    }
  }, [linkedBankAccounts?.data]);

  return (
    <>
      <div className="card-box">
        <div className="card-box__items-details">
          <Image src={bankIcon} />
          <div className="card-box__items-left">
            <div className="items_content">
              <span className="btn-title__top">
                {global.translate('Bank account')}
              </span>
              <span className="btn-title__bottom">
                {global.translate('Transfer funds to a bank account')}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {providersList ? (
        <>
          {bankOptions?.length !== 0 ? (
            <div className="card-box__field">
              <span className="card-title">
                {global.translate('Choose bank')}
              </span>
              <div className="input-field">
                <Dropdown
                  selection
                  options={bankOptions}
                  value={selectedBankAccount}
                  loading={linkedBankAccounts?.loading}
                  placeholder={global.translate('Select a bank')}
                  size="large"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          ) : (
            <div className="card-box__field">
              <div className="card-box__items-details">
                <div className="btn-content">
                  <span>
                    {global.translate(
                      'You have not connected any of your bank accounts',
                    )}
                  </span>
                  <Link className="link-btn" to="/wallets">
                    {global.translate(' Add a bank account')}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="load-data">
          <Image
            className="animate-placeholder loader-others"
            src={InputLoader}
          />
        </div>
      )}
      <div className="card-box__field">
        <span className="card-title">
          {global.translate('Amount')}
        </span>
        <div className="input-field">
          <Input
            label={{
              basic: true,
              content: currentOption?.CurrencyCode,
            }}
            disabled={bankOptions?.length !== 0 ? false : true}
            labelPosition="right"
            value={form?.amount}
            onChange={onHandleChangeTransferFundsToBankInput}
            name="amount"
            placeholder={global.translate('0.0')}
          />
        </div>
      </div>

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button id="btn-back" onClick={() => setCurrentPage(0)}>
          {global.translate('Back')}
        </Button>

        <Button
          onClick={() => onSubmitWalletTransactionConfirmation()}
          loading={checking}
          disabled={
            enableNextProcess === false || bankOptions?.length !== 0
              ? false
              : true
          }
          style={{ marginLeft: '10px' }}
        >
          {global.translate('Proceed')}
        </Button>
      </div>
    </>
  );
};

export default TransferFundsToBank;
