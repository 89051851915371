export default {
  createBulkPayment: {
    data: [],
    loading: false,
    error: null,
  },
  bulkPaymentList: {
    data: [],
    loading: false,
    error: null,
  },
  bulkPaymentDetails: {
    data: [],
    loading: false,
    error: null,
  },
  addBulkPaymentRecipient: {
    data: [],
    loading: false,
    error: null,
  },
  duplicateBulkPayments: {
    data: [],
    loading: false,
    error: null,
  },
  modifyBulkPaymentRecipient: {
    data: [],
    loading: false,
    error: null,
  },
  calculateBulkPaymentOperation: {
    data: [],
    loading: false,
    error: null,
  },
  executeBulkPaymentOperation: {
    data: [],
    loading: false,
    error: null,
  },
  bulkPaymentTemplate: {
    data: [],
    loading: false,
    error: null,
  },
  helpersList: {
    data: [],
    loading: false,
    error: null,
  },
  addHelpers: {
    data: [],
    loading: false,
    error: null,
  },
  createOrganization: {
    data: [],
    loading: false,
    error: null,
  },
  organizationList: {
    data: [],
    loading: false,
    error: null,
  },
  savingsKPI: {
    data: [],
    loading: false,
    error: null,
  }
};
