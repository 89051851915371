import React, { useState, useEffect } from 'react';
import TransferMoney from 'components/TransferMoney/moneyTransfer';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import confirmTransaction, {
  clearConfirmation,
} from 'redux/actions/moneyTransfer/confirmTransaction';
import { CELINE_MONEY, BANK_TRANSFER } from 'constants/general';
import {
  ACTION_TYPE,
  MNO_GETAWAY_OPERATOR,
  MNO_PROVIDERS_TYPE,
} from 'constants/providersTypes';
import moveFunds from 'redux/actions/moneyTransfer/moveFunds';
import { toast } from 'react-toastify';
import getProviders from 'redux/actions/providers/getProviders';
import sendMoneyToBank from 'redux/actions/walletsAndBanks/sendMoneyToBank';
import getLinkedBankAccounts from 'redux/actions/walletsAndBanks/getLinkedBankAccounts';
import sendFundsToGatewayData, {
  clearSendFundToGatewayStore,
} from 'redux/actions/moneyTransfer/sendFundsToGatewayData';
import getContactList from 'redux/actions/contacts/getContactList';
import gatewayTransferConfirmationData, {
  clearGatewayTransferConfirmation,
} from 'redux/actions/moneyTransfer/gatewayTransferConfirmationData';
import getProvidersCountries from 'redux/actions/providers/getProvidersCountries';
import { TELECOM_OPERATOR } from 'constants/getwayTransaferMoneyType';
import { clearWalletUpdateData } from 'redux/actions/users/walletUpdate';
import { GATEWAY_MOBILE_TRANSFER } from 'constants/getwayOperationTypes';
import { clearFoundUser } from 'redux/actions/contacts/locateWallet';
import { useHistory } from 'react-router-dom';
import verifyGatewayAccount, {
  clearVerifyGateway,
} from 'redux/actions/payBills/verifyGatewayAccount';

const TransferMoneyContainer = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [nextStep, setNextStep] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [PIN, setPIN] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [option, setOption] = useState();
  const [amount, setAmount] = useState('');
  const [targetWallet, setTargetWallet] = useState();
  const [currentWalletOption, setCurrentOption] = useState(null);
  const [bankOptions, setBankOptions] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});
  const [selectedCountry, setSelectedCountry] = useState();
  const [getDefaultProvider, setGetDefaultProvider] = useState();
  const [getSelectedCountry, setGetSelectedCountry] = useState();
  const [bankInfo, setBankInfo] = useState();
  const [form, setForm] = useState({
    amount: '',
    accountName: '',
    accountNumber: '',
    phoneNumber: '',
    countryCode: '',
    firstName: '',
    lastName: '',
    destinationCountry: '',
  });

  const [
    mobileMoneyOperatorForm,
    setMobileMoneyOperatorForm,
  ] = useState({
    accountNumber: '',
    accountName: '',
    destinationCountry: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    amount: '',
  });

  const [providersListOption, setProvidersListOption] = useState(
    null,
  );
  const [getPin, setGetPin] = useState();
  const [
    currentProviderOption,
    setCurrentProviderOption,
  ] = useState();
  const [confirmTransactionPin, setConfirmTransactionPin] = useState(
    {},
  );

  const {
    myWallets: { walletList },
    userData: { data: userData },
  } = useSelector(({ user }) => user);

  const {
    user: { operation, walletUpdate },
    moneyTransfer: {
      confirmTransaction: {
        checking,
        confirmationError,
        confirmationData,
      },
      gatewayTransactionConfirmation,
      moveFundsTo2UWallet,
      sendFundsToGateway,
    },
    providersCountries: { providersList },
    walletsAndBanks: { linkedBankAccounts, sendMoneyToBankAccount },
    providersCountries: { providersCountries },
    contacts: { allContacts },
    payBills: { verifyGatewayAccounts },
  } = useSelector(state => state);

  const verifiedGatewayAccountData = verifyGatewayAccounts;

  useEffect(() => {
    getContactList()(dispatch);
  }, []);

  const displayPopup = ({
    title,
    text,
    icon,
    showConfirmButton,
    showCancelButton,
  }) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showConfirmButton: showConfirmButton,
      showCancelButton: showCancelButton,
      allowOutsideClick: true,
    });
  };

  useEffect(() => {
    // Clean up existing redux data
    return () => {
      clearGatewayTransferConfirmation()(dispatch);
      clearConfirmation()(dispatch);
    }
  }, [])
  useEffect(() => {
    if (walletList) {
      const selectedWallet = walletList.find(
        wallet => wallet.Default === 'YES',
      );

      if (selectedWallet) {
        setCurrentOption(selectedWallet);
      }
    }
  }, [walletList]);

  const onSubmit = () => {
    setNextStep(true);
    setCurrentPage(option);
  };

  const onShowConfirmModal = () => {
    setOpenModal(true);
  };

  const backBtn = () => {
    setNextStep(false);
    clearConfirmation()(dispatch);
    clearGatewayTransferConfirmation()(dispatch);
    setCurrentProviderOption();
    setMobileMoneyOperatorForm();
    clearVerifyGateway()(dispatch);
  };

  // Transfer to M2U wallet
  const onSubmitWalletTransactionConfirmation = () => {
    const data = {
      CountryCode: userData?.OperationCountry,
      Amount: amount || form.amount,
      TargetCurrency:
        targetWallet?.CurrencyCode ||
        currentWalletOption?.CurrencyCode ||
        '',
      TargetType: currentPage === 1 ? CELINE_MONEY : BANK_TRANSFER,
      SourceWallet: currentWalletOption?.AccountNumber,
      TargetWallet: targetWallet?.WalletNumber,
    };
    confirmTransaction(data)(dispatch);
  };

  const moveFundsToM2UWallet = () => {
    const data = {
      PIN: getPin,
      CountryCode: targetWallet?.CountryCode,
      Amount: amount,
      ContactPID: targetWallet?.PID,
      UseDefaultWallet:
        currentWalletOption?.Default === 'YES' ? 'YES' : 'No',
      TargetWallet: targetWallet?.WalletNumber,
      SourceWallet: currentWalletOption?.AccountNumber,
      DateFrom: '',
      DateTo: '',
      Day: '0',
      Reccurent: 'No',
      SendNow: 'YES',
      Reference: '',
      Description: '',
    };

    moveFunds(data, '/TransferFunds2UWallet')(dispatch)();
  };

  useEffect(() => {
    if (verifiedGatewayAccountData?.error) {
      toast.error(verifiedGatewayAccountData?.error?.Description);
    }
  }, [verifiedGatewayAccountData?.error]);

  useEffect(() => {
    if (moveFundsTo2UWallet?.data) {
      history.replace();
      setCurrentPage(0);
      toast.success(`${moveFundsTo2UWallet?.data[0]?.Description}`);
      clearWalletUpdateData()(dispatch);
    }

    if (sendMoneyToBankAccount?.success) {
      setCurrentPage(0);
      clearWalletUpdateData()(dispatch);
      setCurrentProviderOption();
      setGetDefaultProvider();
    }

    if (
      sendFundsToGateway?.data &&
      sendFundsToGateway?.data[0]?.FeedBackRequired === 'YES'
    ) {
      setCurrentPage(0);
      setNextStep(false);
      displayPopup({
        title: global.translate('Pending'),
        text: global.translate(
          sendFundsToGateway?.data[0]?.Description,
        ),
        icon: 'warning',
        showConfirmButton: false,
        showCancelButton: false,
      });
      clearGatewayTransferConfirmation()(dispatch);
      clearConfirmation()(dispatch);
      clearFoundUser()(dispatch);
      setCurrentProviderOption();
      setGetDefaultProvider();
    }

    if (confirmationError) {
      toast.error(
        global.translate(confirmationError[0]?.Description),
      );
      setCurrentProviderOption();
      setGetDefaultProvider();
    }

    if (gatewayTransactionConfirmation?.error) {
      clearGatewayTransferConfirmation()(dispatch);
      setForm();
      setAmount('');
      setMobileMoneyOperatorForm();
    }
  }, [
    moveFundsTo2UWallet?.data,
    sendMoneyToBankAccount?.success,
    sendFundsToGateway,
    confirmationError,
    gatewayTransactionConfirmation?.error,
  ]);

  useEffect(() => {
    if ([1, 2, 3, 5, 0].includes(currentPage)) {
      setForm({
        amount: '',
        accountName: '',
        accountNumber: '',
        phoneNumber: '',
        countryCode: '',
        firstName: '',
        lastName: '',
        destinationCountry: '',
      });
      setAmount('');
      setMobileMoneyOperatorForm({
        accountNumber: '',
        destinationCountry: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        amount: '',
      });
    }

    if (currentPage === 0) {
      clearGatewayTransferConfirmation()(dispatch);
      clearWalletUpdateData()(dispatch);
      clearSendFundToGatewayStore()(dispatch);
      clearConfirmation()(dispatch);
      clearFoundUser()(dispatch);
    }
  }, [currentPage]);

  // Transfer Funds to bank
  /**
   * ? Send fund to your own account
   * ? Send to another account
   */
  useEffect(() => {
    getProviders({
      CountryCode: selectedCountry || userData?.OperationCountry,
      ActionType: '1',
      IncludeSelf: 'YES',
    })(dispatch);
    getLinkedBankAccounts()(dispatch);
    getProvidersCountries()(dispatch);
  }, [selectedCountry]);

  const onHandleChangeTransferFundsToBankInput = event => {
    const { name, value } = event.target;
    const list = { ...mobileMoneyOperatorForm };
    list[name] = value;
    setMobileMoneyOperatorForm(list);
  };

  // Send Funds to your own account
  const sendFoundToYourBankAccountConfirmation = () => {
    const data = {
      TargetCountryCode: userData?.OperationCountry,
      Amount: amount || form.amount,
      GatewayOperationType: GATEWAY_MOBILE_TRANSFER,
      TargetType: currentPage === 1 ? CELINE_MONEY : BANK_TRANSFER,
      Category: bankInfo?.Category,
      ActionType: CELINE_MONEY,
      BrandUnikID: bankInfo?.BrandUnikID,
      ShortName: bankInfo?.ShortName,
      SourceWallet: currentWalletOption?.AccountNumber,
    };
    gatewayTransferConfirmationData(data)(dispatch);
  };

  useEffect(() => {
    if (bankOptions && bankOptions.length !== 0) {
      setBankInfo(
        () =>
          providersList &&
          providersList?.data?.filter(
            provider =>
              provider?.OperatorName ===
              bankOptions[0]?.value?.BankName,
          )[0],
      );
    }
  }, [bankOptions]);

  const sendMoneyToYourBankAccount = () => {
    const data = {
      PIN: getPin,
      TargetType: currentPage === 1 ? CELINE_MONEY : BANK_TRANSFER,
      GatewayOperationType: GATEWAY_MOBILE_TRANSFER,
      Category: bankInfo?.Category,
      ActionType: CELINE_MONEY,
      BrandUnikID: bankInfo?.BrandUnikID,
      ShortName: bankInfo?.ShortName,
      SourceWallet: currentWalletOption?.AccountNumber,
      Amount: form.amount,
      TargetCountryCode: userData?.OperationCountry,
      TargetAccountNumber: selectedBankAccount?.AccountNumber,
      TargetPhoneNumber: userData?.MainPhone,
      FirstName: '',
      LastName: '',
      OperatorID: selectedBankAccount?.BankCode,
      OperatorName: bankInfo?.OperatorName,
      SwiftCode: '',
      IBAN: '',
      PartnerRequest: {
        accountNumber: selectedBankAccount?.AccountNumber,
      },
    };
    sendFundsToGatewayData(data)(dispatch);
  };

  // const send money to mobile operator
  const onSubmitGatewayTransactionConfirmation = () => {
    const data = {
      ActionType: ACTION_TYPE.TRANSFER,
      TargetType: MNO_GETAWAY_OPERATOR,
      GatewayOperationType: MNO_PROVIDERS_TYPE,
      SourceWallet: currentWalletOption?.AccountNumber,
      BrandUnikID:
        currentProviderOption?.BrandUnikID ||
        getDefaultProvider?.BrandUnikID,
      ShortName:
        currentProviderOption?.ShortName ||
        getDefaultProvider?.ShortName,
      Amount: mobileMoneyOperatorForm?.amount,
      TargetCountryCode:
        getSelectedCountry.CountryCode || userData?.OperationCountry,
    };
    gatewayTransferConfirmationData(data)(dispatch);
  };

  const sendMoneyToMobileOperator = () => {
    const {
      amount,
      firstName,
      lastName,
      accountNumber,
      phoneNumber,
      destinationCountry,
      inputField,
      ...restMobileMoneyOperatorForm
    } = mobileMoneyOperatorForm;

    const data = {
      PIN: getPin,
      TargetType: MNO_GETAWAY_OPERATOR,
      GatewayOperationType: MNO_PROVIDERS_TYPE,
      Category: MNO_GETAWAY_OPERATOR,
      ActionType: ACTION_TYPE.TRANSFER,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      ShortName: currentProviderOption?.ShortName,
      SourceWallet: currentWalletOption?.AccountNumber,
      Amount: amount,
      TargetCountryCode:
        getSelectedCountry.CountryCode || userData?.OperationCountry,
      TargetAccountNumber:
        mobileMoneyOperatorForm?.accountNumber || '',
      TargetPhoneNumber:
        mobileMoneyOperatorForm?.phoneNumber || userData?.MainPhone,
      FirstName: mobileMoneyOperatorForm.firstName || '',
      LastName: mobileMoneyOperatorForm.lastName || '',
      OperatorID: currentProviderOption?.OperatorID,
      OperatorName: currentProviderOption?.Title,
      SwiftCode: '',
      IBAN: '',
      billRef: '',
      PartnerRequest: {
        ...restMobileMoneyOperatorForm,
        merchant:
          currentProviderOption?.ShortName ||
          currentProviderOption?.OperatorName,
      },
    };
    sendFundsToGatewayData(data)(dispatch);
  };

  const onSubmitGatewayToSomeoneElse = () => {
    const data = {
      ActionType:
        currentProviderOption?.ActionType ||
        getDefaultProvider?.ActionType,
      TargetType:
        currentProviderOption?.Category ||
        getDefaultProvider?.Category,
      Category:
        currentProviderOption?.Category ||
        getDefaultProvider?.Category,
      GatewayOperationType: GATEWAY_MOBILE_TRANSFER,
      SourceWallet: currentWalletOption?.AccountNumber,
      BrandUnikID:
        currentProviderOption?.BrandUnikID ||
        getDefaultProvider?.BrandUnikID,
      ShortName:
        currentProviderOption?.ShortName ||
        getDefaultProvider?.ShortName,
      Amount: mobileMoneyOperatorForm?.amount,
      TargetCountryCode:
        getSelectedCountry?.CountryCode || userData?.OperationCountry,
    };
    gatewayTransferConfirmationData(data)(dispatch);
  };

  const sendMoneyToSomeElseBankAccount = () => {
    const {
      amount,
      firstName,
      lastName,
      destinationCountry,
      inputField,
      ...restMobileMoneyOperatorForm
    } = mobileMoneyOperatorForm;

    const data = {
      PIN: getPin,
      TargetType: currentProviderOption?.Category,
      GatewayOperationType: GATEWAY_MOBILE_TRANSFER,
      Category: currentProviderOption?.Category,
      ActionType: currentProviderOption?.ActionType,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      ShortName: currentProviderOption?.ShortName,
      SourceWallet: currentWalletOption?.AccountNumber,
      Amount: amount,
      TargetCountryCode:
        getSelectedCountry.CountryCode || userData?.OperationCountry,
      TargetAccountNumber:
        mobileMoneyOperatorForm?.accountNumber ||
        mobileMoneyOperatorForm?.serviceNumber,
      TargetPhoneNumber: mobileMoneyOperatorForm?.phoneNumber,
      FirstName: firstName || '',
      LastName: lastName || '',
      OperatorID: currentProviderOption?.OperatorID,
      OperatorName: currentProviderOption?.Title,
      SwiftCode: '',
      IBAN: '',
      billRef: '',
      PartnerRequest: {
        ...restMobileMoneyOperatorForm,
        merchant:
          currentProviderOption?.ShortName ||
          currentProviderOption?.OperatorName,
      },
    };
    sendFundsToGatewayData(data)(dispatch);
  };

  const onVerifyGatewayAccount = () => {
    const {
      inputField: { verifyURL },
      amount,
      destinationCountry,
      firstName,
      lastName,
      fullPhone,
      phoneNumber,
      ...restMobileMoneyOperatorForm
    } = mobileMoneyOperatorForm;

    const payload = {
      PID: userData?.PID,
      CountryCode: getSelectedCountry?.CountryCode,
      OperatorID: currentProviderOption?.OperatorID,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      VerifyURL: verifyURL || '',
      AccountNumber: {
        accountNumber: restMobileMoneyOperatorForm?.serverNumber,
        ...restMobileMoneyOperatorForm,
        phoneNumber: fullPhone || phoneNumber,
      },
    };
    verifyGatewayAccount(payload)(dispatch);
  };

  let defaultValues = {
    onSubmit: onSubmit,
    nextStep: nextStep,
    setNextStep: setNextStep,
    openModal: openModal,
    setNextStep: setOpenModal,
    PIN: PIN,
    setPIN: setPIN,
    onShowConfirmModal: onShowConfirmModal,
    walletList: walletList,
    setCurrentOption: setCurrentOption,
    currentOption: currentWalletOption,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
    option: option,
    setOption: setOption,
    amount: amount,
    setAmount: setAmount,
    checking: checking,
    onSubmitWalletTransactionConfirmation: onSubmitWalletTransactionConfirmation,
    transactionConfirmationData: confirmationData,
    confirmTransactionPin: confirmTransactionPin,
    setConfirmTransactionPin: setConfirmTransactionPin,
    targetWallet: targetWallet,
    setTargetWallet: setTargetWallet,
    moveFundsToM2UWallet: moveFundsToM2UWallet,
    setGetPin: setGetPin,
    moveFundToM2ULoading: moveFundsTo2UWallet?.loading,
    providersList: providersList,
    currentProviderOption: currentProviderOption,
    setCurrentProviderOption: setCurrentProviderOption,
    allContacts: allContacts,
    onSubmitGatewayToSomeoneElse: onSubmitGatewayToSomeoneElse,
    sendMoneyToSomeElseBankAccount: sendMoneyToSomeElseBankAccount,
    onVerifyGatewayAccount: onVerifyGatewayAccount,
    verifiedGatewayAccountData: verifiedGatewayAccountData,
  };
  // targetWallet
  useEffect(() => {
    if (location?.state?.defaultValues) {
      defaultValues.setCurrentPage(
        location.state.defaultValues.currentPage,
      );
      defaultValues.setTargetWallet(
        location.state.defaultValues.targetWallet,
      );
      defaultValues.setOption(
        location?.state?.defaultValues?.option || 1,
      );
    }
  }, [location?.state?.defaultValues]);

  const transferFundsToBank = {
    form: form,
    setForm: setForm,
    onHandleChangeTransferFundsToBankInput: onHandleChangeTransferFundsToBankInput,
    linkedBankAccounts: linkedBankAccounts,
    bankOptions: bankOptions,
    setBankOptions: setBankOptions,
    selectedBankAccount: selectedBankAccount,
    setSelectedBankAccount: setSelectedBankAccount,
    sendMoneyToYourBankAccount: sendMoneyToYourBankAccount,
    sendMoneyToBankAccount: sendMoneyToBankAccount,
    userData: userData,
    sendMoneyToMobileOperator: sendMoneyToMobileOperator,
    sendFundsToGateway: sendFundsToGateway,
    gatewayTransactionConfirmation: gatewayTransactionConfirmation,
    sendFoundToYourBankAccountConfirmation: sendFoundToYourBankAccountConfirmation,
    onSubmitGatewayTransactionConfirmation: onSubmitGatewayTransactionConfirmation,
    countries: providersCountries?.data,
    countryLoading: providersCountries?.loading,
    selectedCountry: selectedCountry,
    setSelectedCountry: setSelectedCountry,
    defaultCountry: userData?.OperationCountry,
    setMobileMoneyOperatorForm: setMobileMoneyOperatorForm,
    mobileMoneyOperatorForm: mobileMoneyOperatorForm,
    getSelectedCountry: getSelectedCountry,
    setGetSelectedCountry: setGetSelectedCountry,
    getDefaultProvider: getDefaultProvider,
    setGetDefaultProvider: setGetDefaultProvider,
  };

  return (
    <>
      <TransferMoney
        transferFundsToBank={transferFundsToBank}
        defaultValues={defaultValues}
        backBtn={backBtn}
        location={location}
      />
    </>
  );
};

export default TransferMoneyContainer;
