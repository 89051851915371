import React, { useEffect, useState } from 'react';
import nPhoneIcon from 'assets/images/phoneIcon.svg';
import InputLoader from 'assets/images/LoaderRectangle.svg';
import { Button, Image, Input, Dropdown } from 'semantic-ui-react';
import { MNO_GETAWAY_OPERATOR } from 'constants/providersTypes';
import ReusableDropdown from 'components/common/Dropdown/ReusableDropdown';
import DynamicForm from 'components/common/DynamicForm';

const TransferFundsToTelco = props => {
  const {
    loading,
    setCurrentPage,
    providersList,
    currentProviderOption,
    setCurrentProviderOption,
    form,
    currentOption,
    onHandleChangeTransferFundsToBankInput,
    onSubmitGatewayTransactionConfirmation,
    linkedBankAccounts,
    bankOptions,
    setBankOptions,
    selectedBankAccount,
    setSelectedBankAccount,
    userData,
    setForm,
    countries,
    selectedCountry,
    countryLoading,
    setSelectedCountry,
    defaultCountry,
    setMobileMoneyOperatorForm,
    mobileMoneyOperatorForm,
    getSelectedCountry,
    setGetSelectedCountry,
    getDefaultProvider,
    setGetDefaultProvider,
    verifiedGatewayAccountData,
    onVerifyGatewayAccount,
    wallet,
  } = props;
  const [enableNextProcess, setEnableNextProcess] = useState(true);
  const [getTelco, setGetTelco] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const newList = [];
    const secondList = [];
    const providers = providersList?.data?.map(item => {
      if (
        item?.TargetType === MNO_GETAWAY_OPERATOR &&
        item?.Available === 'YES'
      ) {
        newList.push(item);
        secondList.push({
          BrandUnikID: item.BrandUnikID,
          Category: item.Category,
          Img: item.Logo,
          OperatorID: item.OperatorID,
          OperatorName: item.OperatorName,
          PhoneAreaCode: item.PhoneAreaCode,
          Title: item.OperatorName,
        });
      }
    });
    setGetTelco(newList);
    setGetDefaultProvider(secondList[0]);
  }, [providersList, selectedCountry]);

  const selectBankHandler = (_, { value }) => {
    setSelectedBankAccount(value);
  };

  useEffect(() => {
    if (
      linkedBankAccounts?.data !== undefined &&
      Array.isArray(linkedBankAccounts?.data)
    ) {
      setBankOptions(
        linkedBankAccounts?.data?.map(bank => ({
          key: bank?.AccountNumber,
          text: `${bank?.BankName} (${bank?.AccountNumber}) ${bank?.Currency}`,
          value: bank,
        })),
      );
      setSelectedBankAccount(linkedBankAccounts.data[0]);
    }
  }, [linkedBankAccounts?.data]);

  useEffect(() => {
    if (
      ((currentProviderOption !== undefined &&
        mobileMoneyOperatorForm?.phoneNumber !== ' ') ||
        mobileMoneyOperatorForm?.amount !== '') &&
      mobileMoneyOperatorForm?.amount !== undefined
    ) {
      setEnableNextProcess(false);
    }
  }, [
    currentProviderOption,
    mobileMoneyOperatorForm?.phoneNumber,
    mobileMoneyOperatorForm?.amount,
  ]);

  useEffect(() => {
    const newList = [];
    const findCountry = countries.map(item => {
      if (
        item?.CountryCode === selectedCountry ||
        item?.CountryCode === defaultCountry
      ) {
        newList.push(item);
      }
    });
    setGetSelectedCountry(newList[0]);
  }, [selectedCountry, defaultCountry]);

  useEffect(() => {
    const newList = [];
    const countriesList = countries.map(item => {
      newList.push({
        key: item?.CountryCode,
        text: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <img
              src={item?.Flag}
              style={{
                width: '15px',
              }}
            />
            <span
              style={{
                fontSize: '12',
                marginLeft: '10px',
                marginTop: '3px',
              }}
            >
              {item?.CountryCode}
            </span>
          </div>
        ),
        value: item?.CountryCode,
      });
    });
    setCountriesList(newList);
  }, [countries]);

  return (
    <>
      <div className="card-box">
        <div className="card-box__items-details">
          <Image src={nPhoneIcon} />
          <div className="card-box__items-left">
            <div className="items_content">
              <span className="btn-title__top">
                {global.translate('Mobile money operator')}
              </span>
              <span className="btn-title__bottom">
                {global.translate(
                  'Transfer funds to a mobile money operator',
                )}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="card-box__field">
        <span className="card-title">
          {global.translate('Select a destination country')}
        </span>
        <div className="input-field">
          <Dropdown
            selection
            loading={countryLoading}
            options={countriesList}
            value={selectedCountry}
            defaultValue={defaultCountry}
            placeholder={global.translate('Select a country')}
            size="large"
            style={{ width: '100%' }}
            onChange={(data, item) => {
              setSelectedCountry(item.value);
              setMobileMoneyOperatorForm({
                destinationCountry: item.value,
              });
            }}
          />
        </div>
      </div>

      {providersList ? (
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Select operator')}
          </span>
          <div className="input-field">
            <ReusableDropdown
              options={getTelco}
              currentOption={currentProviderOption}
              setCurrentOption={setCurrentProviderOption}
              loading={providersList?.loading}
              placeholder={global.translate(
                'Select mobile money operator',
              )}
            />
          </div>
        </div>
      ) : (
        <div className="load-data">
          <Image
            className="animate-placeholder loader-others"
            src={InputLoader}
          />
        </div>
      )}

      <DynamicForm
        fixedHandleInputLabelPosition={'right'}
        jsonFields={currentProviderOption?.Fields}
        handleChangeInputForm={setMobileMoneyOperatorForm}
        verifiedAccountFields={verifiedGatewayAccountData}
        fixedHandleInputChange={
          onHandleChangeTransferFundsToBankInput
        }
        fixedHandleInputValue={mobileMoneyOperatorForm?.amount}
        fixedHandleInputLabel={{
          basic: true,
          content: currentOption?.CurrencyCode,
        }}
        country={getSelectedCountry}
        verifyAccount={
          mobileMoneyOperatorForm?.inputField?.verifyAccount
        }
      />

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button
          id="btn-back"
          onClick={() => {
            setCurrentPage(0);
            setCurrentProviderOption();
          }}
        >
          {global.translate('Back')}
        </Button>

        <Button
          onClick={() => {
            if (mobileMoneyOperatorForm?.inputField?.verifyAccount) {
              onVerifyGatewayAccount();
            } else {
              onSubmitGatewayTransactionConfirmation();
            }
          }}
          loading={loading}
          disabled={enableNextProcess || wallet?.Balance === '0.00'}
          style={{ marginLeft: '10px' }}
        >
          {mobileMoneyOperatorForm?.inputField?.verifyAccount
            ? global.translate('Next')
            : global.translate('Proceed')}
        </Button>
      </div>
    </>
  );
};

export default TransferFundsToTelco;
