import React, { useState, useEffect } from 'react';
import { Button, Image, Input } from 'semantic-ui-react';
import ShowCurrentBox from '../common/showCurrentBox';
import ContentBox from 'components/common/ContentBox';
import WalletDropDown from 'components/common/WalletDropDown';
import m2uIcon from 'assets/images/m2uIcon.svg';
import bankIcon from 'assets/images/bankIcon.svg';
import nPhoneIcon from 'assets/images/phoneIcon.svg';
import circleIcon from 'assets/images/circleIcon.svg';
import circleIconChecked from 'assets/images/circleIconChecked.svg';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import TransferFundsToWallet from './transferFundsToWallet';
import ConfirmationPage from './confirmationPage';
import TransferFundsToBank from './transferFundsToBank';
import TransferFundsToTelco from './transferFundsToTelco';
import ToSomeoneElseAccount from './toSomeoneElseAccount';

const TransferMoney = props => {
  const {
    transferFundsToBank,
    defaultValues,
    backBtn,
    location,
  } = props;
  useEffect(() => {
    if (
      defaultValues.transactionConfirmationData !== null ||
      transferFundsToBank?.gatewayTransactionConfirmation?.data !==
        null
    ) {
      defaultValues.setCurrentPage(4);
    }
  }, [
    defaultValues.transactionConfirmationData,
    transferFundsToBank?.gatewayTransactionConfirmation,
  ]);

  return (
    <>
      <ContentBox
        title={global.translate('Transfer')}
        backBtn={backBtn}
      >
        <ShowCurrentBox steps={defaultValues.nextStep} />
        <>
          <form>
            <h3 className="page-title">
              {global.translate('Transfer')}
            </h3>

            {[0, 1, 2, 3, 5].includes(defaultValues.currentPage) ? (
              <>
                <div>
                  {defaultValues.walletList?.length !== 0 ? (
                    <>
                      <div className="cash-out-title">
                        {global.translate('Select wallet')}
                      </div>
                      <div className="wallet-select">
                        <WalletDropDown
                          walletList={defaultValues.walletList}
                          setCurrentOption={
                            defaultValues.setCurrentOption
                          }
                          currentOption={defaultValues.currentOption}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="load-data"
                      style={{
                        paddingTop: '8px',
                        paddingBottom: '12px',
                      }}
                    >
                      <Image
                        src={LoadWalletImg}
                        className="animate-placeholder"
                      />
                    </div>
                  )}
                </div>
                {defaultValues.currentPage === 0 ? (
                  <div className="card-box">
                    <div
                      className="card-box__items"
                      onClick={() => defaultValues.setOption(1)}
                    >
                      <Image src={m2uIcon} />
                      <div className="card-box__items-left">
                        <div className="items_content">
                          <span className="btn-title__top">
                            {global.translate('M2U Wallet')}
                          </span>
                          <span className="btn-title__bottom">
                            {global.translate(
                              'Transfer funds to wallet',
                            )}
                          </span>
                        </div>
                        <Image
                          src={
                            defaultValues.option === 1
                              ? circleIconChecked
                              : circleIcon
                          }
                        />
                      </div>
                      <div></div>
                    </div>
                    <div
                      className="card-box__items"
                      onClick={() => defaultValues.setOption(2)}
                    >
                      <Image src={bankIcon} />
                      <div className="card-box__items-left">
                        <div className="items_content">
                          <span className="btn-title__top">
                            {global.translate('Bank account')}
                          </span>
                          <span className="btn-title__bottom">
                            {global.translate(
                              'Transfer funds to a bank account',
                            )}
                          </span>
                        </div>
                        <Image
                          src={
                            defaultValues.option === 2
                              ? circleIconChecked
                              : circleIcon
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="card-box__items"
                      onClick={() => defaultValues.setOption(5)}
                    >
                      <Image src={bankIcon} />
                      <div className="card-box__items-left">
                        <div className="items_content">
                          <span className="btn-title__top">
                            {global.translate(
                              `To someone else's account`,
                            )}
                          </span>
                          <span className="btn-title__bottom">
                            {global.translate(
                              `Transfer funds to a bank account`,
                            )}
                          </span>
                        </div>
                        <Image
                          src={
                            defaultValues.option === 5
                              ? circleIconChecked
                              : circleIcon
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="card-box__items"
                      onClick={() => defaultValues.setOption(3)}
                      style={{ borderBottom: 'none' }}
                    >
                      <Image src={nPhoneIcon} />
                      <div className="card-box__items-left">
                        <div className="items_content">
                          {' '}
                          <span className="btn-title__top">
                            {global.translate(
                              'Mobile money operator',
                            )}
                          </span>
                          <span className="btn-title__bottom">
                            {global.translate(
                              'Transfer funds to a mobile money operator',
                            )}
                          </span>
                        </div>
                        <Image
                          src={
                            defaultValues.option === 3
                              ? circleIconChecked
                              : circleIcon
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </form>
        </>

        {defaultValues.currentPage === 1 ? (
          <TransferFundsToWallet
            currentOption={defaultValues.currentOption}
            amount={defaultValues.amount}
            setAmount={defaultValues.setAmount}
            checking={defaultValues.checking}
            setCurrentPage={defaultValues.setCurrentPage}
            onSubmitWalletTransactionConfirmation={
              defaultValues.onSubmitWalletTransactionConfirmation
            }
            targetWallet={defaultValues.targetWallet}
            setTargetWallet={defaultValues.setTargetWallet}
            location={location}
          />
        ) : null}

        {defaultValues.currentPage === 2 && (
          <TransferFundsToBank
            loading={
              transferFundsToBank?.gatewayTransactionConfirmation
                .loading
            }
            location={location}
            checking={defaultValues.checking}
            enableNextProcess={defaultValues.enableNextProcess}
            setCurrentPage={defaultValues.setCurrentPage}
            providersList={defaultValues.providersList}
            currentProviderOption={
              defaultValues.currentProviderOption
            }
            setCurrentProviderOption={
              defaultValues.setCurrentProviderOption
            }
            form={transferFundsToBank.form}
            setForm={transferFundsToBank.setForm}
            currentOption={defaultValues.currentOption}
            onHandleChangeTransferFundsToBankInput={
              transferFundsToBank.onHandleChangeTransferFundsToBankInput
            }
            onSubmitWalletTransactionConfirmation={
              transferFundsToBank.sendFoundToYourBankAccountConfirmation
            }
            linkedBankAccounts={
              transferFundsToBank.linkedBankAccounts
            }
            bankOptions={transferFundsToBank.bankOptions}
            setBankOptions={transferFundsToBank.setBankOptions}
            selectedBankAccount={
              transferFundsToBank.selectedBankAccount
            }
            setSelectedBankAccount={
              transferFundsToBank.setSelectedBankAccount
            }
            countries={transferFundsToBank.countries}
            countryLoading={transferFundsToBank.countryLoading}
            selectedCountry={transferFundsToBank.selectedCountry}
            setSelectedCountry={
              transferFundsToBank.setSelectedCountry
            }
            defaultCountry={transferFundsToBank.defaultCountry}
          />
        )}

        {defaultValues.currentPage === 3 ? (
          <TransferFundsToTelco
            loading={
              transferFundsToBank?.gatewayTransactionConfirmation
                .loading
            }
            enableNextProcess={defaultValues.enableNextProcess}
            setCurrentPage={defaultValues.setCurrentPage}
            providersList={defaultValues.providersList}
            currentProviderOption={
              defaultValues.currentProviderOption
            }
            setCurrentProviderOption={
              defaultValues.setCurrentProviderOption
            }
            form={transferFundsToBank.form}
            currentOption={defaultValues.currentOption}
            onHandleChangeTransferFundsToBankInput={
              transferFundsToBank.onHandleChangeTransferFundsToBankInput
            }
            onSubmitGatewayTransactionConfirmation={
              transferFundsToBank.onSubmitGatewayTransactionConfirmation
            }
            linkedBankAccounts={
              transferFundsToBank.linkedBankAccounts
            }
            bankOptions={transferFundsToBank.bankOptions}
            setBankOptions={transferFundsToBank.setBankOptions}
            selectedBankAccount={
              transferFundsToBank.selectedBankAccount
            }
            setSelectedBankAccount={
              transferFundsToBank.setSelectedBankAccount
            }
            userData={transferFundsToBank.userData}
            setForm={transferFundsToBank.setForm}
            countries={transferFundsToBank.countries}
            countryLoading={transferFundsToBank.countryLoading}
            selectedCountry={transferFundsToBank.selectedCountry}
            setSelectedCountry={
              transferFundsToBank.setSelectedCountry
            }
            defaultCountry={transferFundsToBank.defaultCountry}
            setMobileMoneyOperatorForm={
              transferFundsToBank.setMobileMoneyOperatorForm
            }
            mobileMoneyOperatorForm={
              transferFundsToBank.mobileMoneyOperatorForm
            }
            getSelectedCountry={
              transferFundsToBank?.getSelectedCountry
            }
            setGetSelectedCountry={
              transferFundsToBank?.setGetSelectedCountry
            }
            getDefaultProvider={
              transferFundsToBank.getDefaultProvider
            }
            setGetDefaultProvider={
              transferFundsToBank.setGetDefaultProvider
            }
            verifiedGatewayAccountData={
              defaultValues.verifiedGatewayAccountData
            }
            onVerifyGatewayAccount={
              defaultValues.onVerifyGatewayAccount
            }
            wallet={defaultValues.currentOption}
          />
        ) : null}

        {defaultValues.currentPage === 5 ? (
          <ToSomeoneElseAccount
            loading={
              transferFundsToBank?.gatewayTransactionConfirmation
                .loading
            }
            enableNextProcess={defaultValues.enableNextProcess}
            setCurrentPage={defaultValues.setCurrentPage}
            providersList={defaultValues.providersList}
            currentProviderOption={
              defaultValues.currentProviderOption
            }
            setCurrentProviderOption={
              defaultValues.setCurrentProviderOption
            }
            form={transferFundsToBank.form}
            currentOption={defaultValues.currentOption}
            onHandleChangeTransferFundsToBankInput={
              transferFundsToBank.onHandleChangeTransferFundsToBankInput
            }
            onSubmitGatewayTransactionConfirmation={
              transferFundsToBank.onSubmitGatewayTransactionConfirmation
            }
            linkedBankAccounts={
              transferFundsToBank.linkedBankAccounts
            }
            bankOptions={transferFundsToBank.bankOptions}
            setBankOptions={transferFundsToBank.setBankOptions}
            selectedBankAccount={
              transferFundsToBank.selectedBankAccount
            }
            setSelectedBankAccount={
              transferFundsToBank.setSelectedBankAccount
            }
            userData={transferFundsToBank.userData}
            setForm={transferFundsToBank.setForm}
            countries={transferFundsToBank.countries}
            countryLoading={transferFundsToBank.countryLoading}
            selectedCountry={transferFundsToBank.selectedCountry}
            setSelectedCountry={
              transferFundsToBank.setSelectedCountry
            }
            defaultCountry={transferFundsToBank.defaultCountry}
            setMobileMoneyOperatorForm={
              transferFundsToBank.setMobileMoneyOperatorForm
            }
            mobileMoneyOperatorForm={
              transferFundsToBank.mobileMoneyOperatorForm
            }
            getSelectedCountry={
              transferFundsToBank?.getSelectedCountry
            }
            setGetSelectedCountry={
              transferFundsToBank?.setGetSelectedCountry
            }
            getDefaultProvider={
              transferFundsToBank.getDefaultProvider
            }
            setGetDefaultProvider={
              transferFundsToBank.setGetDefaultProvider
            }
            onSubmitGatewayToSomeoneElse={
              defaultValues.onSubmitGatewayToSomeoneElse
            }
            onVerifyGatewayAccount={
              defaultValues.onVerifyGatewayAccount
            }
            verifiedGatewayAccountData={
              defaultValues.verifiedGatewayAccountData
            }
            wallet={defaultValues.currentOption}
          />
        ) : null}

        {defaultValues.currentPage === 4 ? (
          <ConfirmationPage
            transactionConfirmationData={
              defaultValues.transactionConfirmationData ||
              transferFundsToBank?.gatewayTransactionConfirmation
                ?.data
            }
            confirmTransactionPin={
              defaultValues.confirmTransactionPin
            }
            setConfirmTransactionPin={
              defaultValues.setConfirmTransactionPin
            }
            setCurrentPage={defaultValues.setCurrentPage}
            moveFundsToM2UWallet={defaultValues.moveFundsToM2UWallet}
            setGetPin={defaultValues.setGetPin}
            loading={
              defaultValues.moveFundToM2ULoading ||
              transferFundsToBank.sendMoneyToBankAccount?.loading ||
              transferFundsToBank.sendFundsToGateway?.loading
            }
            currentPage={defaultValues.currentPage}
            option={defaultValues.option}
            sendMoneyToYourBankAccount={
              transferFundsToBank.sendMoneyToYourBankAccount
            }
            sendMoneyToMobileOperator={
              transferFundsToBank.sendMoneyToMobileOperator
            }
            sendMoneyToSomeElseBankAccount={
              defaultValues.sendMoneyToSomeElseBankAccount
            }
            location={location}
          />
        ) : null}

        {defaultValues.currentPage === 0 ? (
          <div className="bottom-transition__box">
            <div></div>
            <Button
              onClick={defaultValues.onSubmit}
              disabled={
                defaultValues.option !== undefined &&
                defaultValues.walletList?.length !== 0
                  ? false
                  : true
              }
              style={{ marginLeft: '20px' }}
            >
              {global.translate('Proceed')}
            </Button>
          </div>
        ) : null}
        <div className="bottom-transition__box"></div>
      </ContentBox>
    </>
  );
};

export default TransferMoney;
